import styled from "styled-components";
import {
  BP,
  Colors,
  Rem,
  Ratio,
} from "../../commons/Theme";

export const StyledAppStore = styled.div``;

export const StyledAppStoreGrid = styled.div`
  padding: ${Rem(20)} 0;

  @media (${BP.ipad}) {
    padding: 0;
  }
`;

export const StyledAppStoreGridTitle = styled.h4`
  font-size: ${Rem(14)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(4)};
  padding-bottom: ${Rem(24)};
  text-transform: uppercase;

  @media (${BP.ipad}) {
    text-align: center;
    font-size: ${Rem(15)};
  }
`;

export const StyledAppStoreGridSubTitle = styled.p`
  font-size: ${Rem(30)};
  line-height: ${Rem(37)};
  padding-bottom: ${Rem(24)};

  @media (${BP.ipad}) {
    text-align: center;
    font-size: ${Rem(50)};
    line-height: ${Rem(60)};
  }
`;

export const StyledAppStoreGridItems = styled.div`
  display: flex;
  flex-direction: column;

  @media (${BP.ipad}) {
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
  }
`;

export const StyledAppStoreGridItem = styled.div`
  margin-bottom: ${Rem(40)};
  @media (${BP.ipad}) {
    margin-bottom: 0;
    width: ${Rem(310)};
  }
`;

export const StyledAppStoreGridItemImage = styled.div`
  height: 300px;
  display: flex;
  align-items: center;
  margin-bottom: ${Rem(24)};
`;

export const StyledAppStoreGridItemTitle = styled.h2`
  font-size: ${Rem(15)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(5)};
  padding-bottom: ${Rem(16)};
  text-transform: uppercase;
`;

export const StyledAppStoreGridItemParagraph = styled.p`
  font-size: ${Rem(14)};
  line-height: ${Rem(24)};
  padding-bottom: ${Rem(40)};
  max-width: 600px;
`;

export const StyledAppStoreGridItemCta = styled.div`
  display: inline-block;
`;
