import React from "react";
import {
  StyledAppStore,
  StyledAppStoreGrid,
  StyledAppStoreGridTitle,
  StyledAppStoreGridSubTitle,
  StyledAppStoreGridItems,
  StyledAppStoreGridItem,
  StyledAppStoreGridItemImage,
  StyledAppStoreGridItemTitle,
  StyledAppStoreGridItemParagraph,
  StyledAppStoreGridItemCta,
} from "./style.jsx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { StyledGridRow } from "../../commons/Grid";
import Button from "../Button/Button";
import Icon, { icons } from "../Icon/Icon";
import { Colors } from "../../commons/Theme";

export default ({ grid, allImages }) => {
  return (
    <StyledAppStore>
      <StyledGridRow isFull>
        <StyledAppStoreGrid>
          <StyledGridRow noMargin>
            <StyledAppStoreGridTitle
              dangerouslySetInnerHTML={{ __html: grid.title }}
            />
            <StyledAppStoreGridSubTitle
              dangerouslySetInnerHTML={{ __html: grid.subtitle }}
            />
            <StyledAppStoreGridItems>
              {grid.items.map((item, index) => {
                const imageData = allImages.find(e => e.path === item.image);
                return (
                  <>
                    <StyledAppStoreGridItem key={index}>
                      <StyledAppStoreGridItemImage>
                        <GatsbyImage style={{ "max-width": "400px" }} image={getImage(imageData)} alt={imageData.alt} />
                      </StyledAppStoreGridItemImage>
                      <StyledAppStoreGridItemTitle>
                        {item.title}
                      </StyledAppStoreGridItemTitle>
                      <StyledAppStoreGridItemParagraph>
                        {item.paragraph}
                      </StyledAppStoreGridItemParagraph>
                      <StyledAppStoreGridItemCta>
                        <Button as="div" to={item.cta.url}>
                          {item.cta.label}{" "}
                          <Icon icon={icons.arrowRight} color={Colors.black} />
                        </Button>
                      </StyledAppStoreGridItemCta>
                    </StyledAppStoreGridItem>
                  </>
                )
              })}
            </StyledAppStoreGridItems>
          </StyledGridRow>
        </StyledAppStoreGrid>
      </StyledGridRow>
    </StyledAppStore>
  );
};
